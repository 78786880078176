import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/index',
    name: 'index',
    component:() => import('../views/index/index')
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('../views/details/index')
  },
  {
    path: '/',
    name: 'review',
    component: () => import('../views/review/index')
  },
  {
    path: '/meeting',
    name: 'meeting',
    component: () => import('../views/ljdh/index')
  },
  {
    path: '/dhhead',
    name: 'dhhead',
    component: () => import('../views/ljdh/dhhead')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
