<template>
  <div>
    <!-- <Hear></Hear> -->
    <router-view/>
    <Foot></Foot>
  </div>
    
</template>
<script>
// import Hear from '@/components/hear.vue'
import Foot from '@/components/foot.vue'
export default {
  components: {
    Foot
  }
}
</script>
<style>
*{
  margin: 0;
  padding: 0;
}
</style>
